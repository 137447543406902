export default {
  methods: {
    // Common validation rules handler
    rulesHandler (item, value) {
      if (item.validation) {
        if ((item.type === 5 || item.type === 15) && item.default_value && item.default_value.is_multiselect && value && !value.length) return this.getValidation(item.validation, 'multiselect')
        else if (!value) return this.getValidation(item.validation, null)
        else if (item.validationscript) {
          let validationScript = this.decodeStringTobase64(item.validationscript)
          validationScript = `var valid;\n${validationScript}\n return valid`
          // eslint-disable-next-line
          let validationFunction = new Function('instance', 'input', 'data', validationScript)
          const result = validationFunction(this, value, this.fields)
          return result ? [result] : []
        }
      }
    },
    getValidation (validate, type = null) {
      switch (validate) {
        case 'required':
          if (type) return this.$_multiSelectValidation
          else return this.$_requiredValidation
        default:
          return null
      }
    }
  }
}
